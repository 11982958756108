@font-face {
  font-family: "Inter-Medium";
  src: local("Inter"), url("./assets/fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter"), url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}

.App {
  text-align: center;
  background-color: #f5f5f5;
  min-height: 100vh;
  font-family: Inter-Medium;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #fff;
  background-color: #4243ed;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.landing-row {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1f2937;
}

.landing-row-item {
  padding-right: 10%;
  padding-left: 10%;
  max-width: 600px;
}

.home-screen {
  height: 750px;
  max-width: 100%; /* Ensures the image never exceeds the viewport width */
  height: auto; /* Maintains the original aspect ratio */
}

.features-container {
  margin-top: 12px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.feature {
  position: relative;
  background-color: white;
  padding: 12px;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 60px;
  padding-top: 40px;
  border-radius: 6px;
  flex: 1;
  text-align: left;
  display: block;
  box-sizing: border-box;
}

.feature-icon {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

#feature-icon-1 {
  background-color: #4243ed;
}
#feature-icon-2 {
  background-color: #4b5563;
}
#feature-icon-3 {
  background-color: #ec3186;
}

.feature-title {
  font-family: Inter-Bold;
  font-size: calc(10px + 1vmin);
  color: #4b5563;
}

.feature-body {
  font-size: calc(10px + 0.5vmin);
}

.badges-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.badge {
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
  max-width: 150px;
}
.bid-icon {
  box-sizing: border-box;
  background-color: cyan;
  height: 32px;
}

.custom-link {
  text-decoration: none;
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  font-size: calc(10px + 1vmin);
}

.custom-link:hover {
  text-decoration: underline; /* Add underline on hover if desired */
}

.menu-toggle {
  display: none; /* Hide by default */
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  margin-right: 12px;
  font-size: calc(10px + 4vmin);
}

.menu-toggle:hover {
  background-color: #4847e5;
  border-radius: 6px;
}

/* Navigation styles */
.nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 24px;
}

.content-container {
  padding: 24px;
  text-align: left;
}

.conent-section {
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding: 8px 16px;
  border-radius: 7px;
  margin: 12px 0;
}

.phone-example {
  width: 250px;
}

.h-stack {
  display: flex;
  flex-direction: row;
}

.v-stack {
  display: flex;
  flex-direction: column;
}

button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* Media query for viewport height less than 768px */
@media (max-width: 768px) {
  .landing-row {
    flex-direction: column;
  }

  .features-container {
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
  }

  .menu-toggle {
    display: block; /* Show the hamburger icon */
  }

  .nav {
    flex-direction: column; /* Stack menu items vertically */
    position: absolute;
    top: -100%; /* Adjust as needed based on your design */
    width: 100%;
    background-color: #4243ed;
    transition: top 0.3s ease;
    margin-top: 2px;
  }

  .nav.open {
    top: 10vmin; /* Slide in the menu */
  }

  .nav li {
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid white;
  }
}
